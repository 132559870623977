angular.module('MyHippoProducer.Components').directive('basicTile',  function () {
    return {
        restrict: 'E',
        scope: {
            title: '<',
            section: '<',
            itemId: '<',
        },
        transclude: true,
        templateUrl: '/components/form/tiles/layouts/basic-tile/basic-tile.html',
        controller: function ($scope, PolicyService, $mdDialog, WorkflowAdapter) {
            const { properties, fields } = $scope.section;
            $scope.isEditMode = PolicyService.isEditMode;

            $scope.delete = function () {
                $scope.section.deleteItem($scope.itemId);
                // Set toggle to false if no items
                const visibilityKey = $scope.section.visibilityKey;
                if (visibilityKey && $scope.section.items.length === 0) {
                    const field = WorkflowAdapter.getField(visibilityKey);
                    if (field.type === 'boolean') { field.display = false; }
                }
            };

            $scope.edit = (itemId) => {
                $mdDialog.show({
                    locals: { fields, section: $scope.section },
                    parent: angular.element(document.body),
                    controller: function ($scope, fields, section) {
                        $scope.createRange = _.range;
                        $scope.policyInfo = PolicyService.policyInfo;
                        $scope.forms = { formData: {} };
                        $scope.fields = fields;
                        $scope.title = properties.title;

                        const items = _.get(PolicyService.policyInfo, properties.parent_key);
                        const originalItem = items.find(item => item.id === itemId);
                        $scope.data = _.cloneDeep(originalItem);

                        $scope.save = function () {
                            if ($scope.forms.formData.$valid) {
                                section.saveItem($scope.data);
                                $mdDialog.hide();
                            }
                        };

                        $scope.states = PolicyService.getAllStates();
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        };
                    },
                    templateUrl: `/modals/edit-forms/${properties.key}.html`,
                });
            };
        },
    };
});
